@media only screen and (max-width: 375px) {
    .web-hide {
      display: block !important;
    }
    .mob-hide {
      display: none !important;
    }
    .outer-row
    {
      flex-wrap: wrap;
    }
    .inner-row
    {
      flex: 1;
      margin-right: 0px !important;
    }
    .outer-row-50
    {
      flex-wrap: wrap;
      width: 100% !important;
    }
    .logo-block {
      width: 52% !important;
    }
    .box-50
    {
      width: 100% !important;
    }
    
    
    
  } 
  
  /* Extra small devices (portrait phones, less than 576px)*/
  @media (max-width: 575.98px) 
  { 
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
  
    .web-hide {
      display: block !important;
    }
    .mob-hide {
      display: none !important;
    }
    .outer-row
    {
      flex-wrap: wrap;
    }
    .inner-row
    {
      flex: 1;
      margin-right: 0px !important;
    }
    .outer-row-50
    {
      flex-wrap: wrap;
      width: 100% !important;
    }
    .logo-block {
      width: 52% !important;
    }
    .box-50
    {
      width: 100% !important;
    }
    
  }
  
  @media only screen and (min-width: 600px) {
  
    /* For tablets: */
    .col-s-1 {width: 8.33%;}
    .col-s-2 {width: 16.66%;}
    .col-s-3 {width: 25%;}
    .col-s-4 {width: 33.33%;}
    .col-s-5 {width: 41.66%;}
    .col-s-6 {width: 50%;}
    .col-s-7 {width: 58.33%;}
    .col-s-8 {width: 66.66%;}
    .col-s-9 {width: 75%;}
    .col-s-10 {width: 83.33%;}
    .col-s-11 {width: 91.66%;}
    .col-s-12 {width: 100%;}
  
    .web-hide {
      display: none !important;
    }
    
  }
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
  
    .web-hide {
      display: none !important;
    }
    .menu-mobile
    {
      display: none !important;
    }

    
    
  }
  
  
  /* Small devices (landscape phones, 576px and up)*/
  @media (min-width: 576px) and (max-width: 767.98px) 
  { 
    .web-hide {
      display: none !important;
    }
    .logocls
    {
      max-width: 70% !important;
    }
    .outer-row
    {
      flex-wrap: wrap;
    }
    .inner-row
    {
      flex: 1;
      margin-right: 0px !important;
    }
    .outer-row-50
    {
      flex-wrap: wrap;
      width: 100% !important;
    }
    .logo-block {
      width: 52% !important;
    }
    .box-50
    {
      width: 100% !important;
    }
    
  }
  
  /* Medium devices (tablets, 768px and up)*/
  @media (min-width: 768px) and (max-width: 991.98px) 
  { 
    .web-hide {
      display: none !important;
    }
    .mobile-pill {
      display: none !important;
    }
    
  }
  
  /* Large devices (desktops, 992px and up)*/
  @media (min-width: 992px) and (max-width: 1199.98px) 
  { 
    .web-hide {
      display: none !important;
    }
    .mobile-pill {
      display: none !important;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up)*/
  @media (min-width: 1200px) 
  { 
    .web-hide {
      display: none !important;
    }
    .mobile-pill {
      display: none !important;
    }
  }
  
  
  /*other than responsive*/
  .web-hide {
    display: none;
  }
  